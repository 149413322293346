export const MESSAGES = {
  ru: {
    loading: {
      title: "Ваш запрос обрабатывается",
      description: "Подождите несколько секунд или обновите страницу",
    },
    blocked: {
      title: "Связь с банком недоступна из вашего местоположения",
      description:
        "Сервис недоступен из вашего местоположения из-за ограничений по приёму обращений с иностранных IP-адресов (доменов), страной происхождения которых являются недружественные Российской Федерации государства",
      footer:
        "Убедитесь, что на вашем устройстве выключен VPN или смените местоположение и попробуйте отправить запрос еще раз",
    },
    error: {
      title: "Сервер не отвечает",
      description: "Обновите страницу и отправьте запрос повторно",
      footer:
        'Если после нескольких попыток сообщение с ошибкой продолжает отображаться на странице, пожалуйста, отправьте информацию на почту <a class="BlackListCheck__link" href="mailto:tech-support@131.ru"\n>tech-support@131.ru</a>',
    },
  },
  en: {
    loading: {
      title: "Your request is being processed",
      description: "Wait a few seconds or refresh the page",
    },
    blocked: {
      title: "Unable to connect to the bank from your location",
      description:
          "The service is not available in your location due to the restrictions on requests from foreign IP addresses (domains) registered in states unfriendly to the Russian Federation",
      footer:
          "Make sure that VPN is disabled on your device, or change your location and retry the request",
    },
    error: {
      title: "Server not responding",
      description: "Refresh the page and retry the request",
      footer:
          'If the error message is still displayed on the page after several attempts, please contact us at <a class="BlackListCheck__link" href="mailto:tech-support@131.ru"\n>tech-support@131.ru</a>',
    },
  },
};
