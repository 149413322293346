import React from 'react';
import { useInView } from 'react-intersection-observer';
import ym from 'react-yandex-metrika';
import { useLocation } from "@reach/router";
import * as PropTypes from "prop-types";
import Modal from "react-modal";

import '../../../styles/form/forms/callBackForm.scss';

import useLanguage from "../../../hooks/useLanguage.js";

import iconClose from "../../../images/icons/buttons/close.svg";
import iconCloseMobile from "../../../images/icons/buttons/close-mobile.svg";

import { 
  getCodeStatusCheck, 
  getCodeStatusByContact, 
  getCodeStatusResending 
} from '../../models/verification/index.js';

import ResultScreen from '../ResultScreen.js';
import ProcessScreen from '../ProcessScreen.js';
import CodeCheck from '../CodeCheck/CodeCheck.js';

import LinkButton from '../../../components/ContactBank/LinkButton/index.js';

import {
  FORM_SCREENS,
  FORM_SCREEN_DEFAULT, 
  MESSAGES, 
  VERIFICATION_CODE_DATA,
  SCREEN_DATA,
  verificationFieldNames,
} from './constants.js';

import { ResponseCode, ResponseStatus, VerificationType } from '../../../constants.js';
import Button from '../../../components/Button.js';
import { BlacklistContext } from '../../../context/BlacklistContext.js';
import BlackListCheck from "../BlackListCheck/BlackListCheck.js";

const FormVerificationWrapper = ({ 
  canBeFixed = "false", 
  form: FormComponent, 
  formFieldValues, 
  openFormButtonTitle,
  openFormLinkTitle, 
  reachGoalTitle,
  verificationType = VerificationType.Email,
  signUpFormOpened,
  submitForm 
}) => {

  Modal.setAppElement("#___gatsby");

  const { shouldShownBlackListModal } = React.useContext(BlacklistContext);

  const [language] = useLanguage();
  const locales = MESSAGES[language];

  const { href } = useLocation();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [modalIsOpen, setIsOpen] = React.useState(signUpFormOpened);

  const [screenData, setScreenData] = React.useState(SCREEN_DATA);

  const [verificationCodeData, setVerificationCodeData] = React.useState(VERIFICATION_CODE_DATA);
  const [formValues, setFormValues] = React.useState(formFieldValues);

  const handleResultButtonClick = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const resetSmsCodeSeconds = React.useCallback(() => {
    setVerificationCodeData((prevState) => ({ 
      ...prevState, 
      checkLeftSeconds: VERIFICATION_CODE_DATA.checkLeftSeconds 
    }));
  }, [setVerificationCodeData]); 

  const resetCodeErrorMessage = React.useCallback(() => {
    setVerificationCodeData((prevState) => ({ ...prevState, 
      codeErrorMessage: VERIFICATION_CODE_DATA.codeErrorMessage 
    }));
  }, [setVerificationCodeData]);

  const resetVerificationCodeData = React.useCallback(() => {
    setVerificationCodeData(VERIFICATION_CODE_DATA);
  }, [setVerificationCodeData]);

  const sendFormData = React.useCallback(() => {
    setScreenData({screen: 'process'});
    resetVerificationCodeData();

    const sentData = new FormData();

    Object.keys(formValues).forEach(key => {
      const fieldValue = formValues[key];

      if (fieldValue) {
        if (key === 'files') {
          if (fieldValue.length) {
            fieldValue.forEach(file => sentData.append("files[]", file, file.name));
          } 
        } else {
          sentData.append(key, fieldValue);
        }
      }
    });

    sentData.append("source", href); 

    submitForm(sentData)
      .then((sendFosData) => {
        if (sendFosData.status === 'success') {
          setScreenData({
            screen: FORM_SCREENS.success,
            title: locales.checkCode.codeSuccessMessages.numberConfirmed,
            description: locales.checkCode.codeSuccessMessages.ourSpecialistsWillContactYou,
          });
          ym('reachGoal', reachGoalTitle, { params: { url: href } });
        } else {
          setScreenData({screen: FORM_SCREENS.error});
        }
      })
      .catch(() => {
        setScreenData({screen: FORM_SCREENS.error, description: locales.checkCode.codeErrorMessages.pleaseRetry});
      });
  }, [formValues, setScreenData, setFormValues]);

  const sendSmsCode = React.useCallback((authCode) => {
    resetCodeErrorMessage();
    return getCodeStatusCheck(verificationCodeData.uuid, authCode, verificationType)
      .then(({checkData, smsStatus}) => {
        if(smsStatus.codeStatus === ResponseStatus.Success) {
          
          if(smsStatus.data.isChecked) {
            sendFormData();

            return;
          }

          if (smsStatus.data.activeLeftSeconds <= 0) { // Если основное время кода закончилось
            setScreenData({
              isButtonVisible: false,
              screen: FORM_SCREENS.error, 
              title: locales.checkCode.codeErrorMessages.mainTimeHasExpired,
              description: locales.checkCode.codeErrorMessages.pleaseRetry,
            });

            return;
          }

          if (!smsStatus.data.isCanBeChecked) { // Если код не может быть больше проверен
            setScreenData({
              isButtonVisible: false,
              screen: FORM_SCREENS.error,
              title: locales.checkCode.codeErrorMessages.codeNumberInputAttemptsExceeded.title, 
              description: locales.checkCode.codeErrorMessages.codeNumberInputAttemptsExceeded.description, 
            });

            return;
          }
        }

        if (checkData.codeStatus === ResponseStatus.Error) {
          const CHECK_CODE_ERROR_STATUSES = {
            invalid_auth_code: locales.checkCode.codeErrorMessages.invalidCode, // Код не совпадает с отправленным
            message_already_checked: locales.checkCode.codeErrorMessages[verificationType].codeAlreadyChecked, // Код уже проверен
            message_not_found: locales.checkCode.codeErrorMessages.codeExpired, // Письмо не найдено или истекло действие кода
            message_check_exhausted: locales.checkCode.codeErrorMessages.codeInputLimitExceeded, // Все попытки подтверждения кода исчерпаны
            message_resending_exhausted: locales.checkCode.codeErrorMessages.allResubmissionsExhausted, // Все попытки отправки кода исчерпаны
          }

          const checkCodeErrorMessage = CHECK_CODE_ERROR_STATUSES[checkData.codeError.code];

          if (checkCodeErrorMessage) {
            setVerificationCodeData((prevState) => ({ ...prevState, codeErrorMessage: checkCodeErrorMessage }));
          }
        }      
      })
      .catch(() => {
        setScreenData({screen: FORM_SCREENS.error});
      });

  }, [verificationCodeData, setScreenData, setVerificationCodeData, sendFormData]);

  const resendCode = React.useCallback(() => {
    resetCodeErrorMessage();
    return getCodeStatusResending(verificationCodeData.uuid, verificationType)
      .then((resendData) => {

        if (resendData.codeStatus === 'success') {
          setVerificationCodeData((prevState) => ({ ...prevState, ...resendData.data }));

          return;
        } 

        if (resendData.codeError.code === 'message_inactive') {
          setScreenData({
            isButtonVisible: false,
            screen: FORM_SCREENS.error, 
            title: locales.checkCode.codeErrorMessages.mainTimeHasExpired,
            description: locales.checkCode.codeErrorMessages.pleaseRetry
          });

          return;
        }

        if (
          resendData.codeError.code === 'message_resending_exhausted' || 
          resendData.codeError.code === 'message_resending_exhausted' || 
          resendData.codeError.code === 'message_still_alive'
        ) {
          setScreenData({
            isButtonVisible: false,
            screen: FORM_SCREENS.error, 
            title: locales.checkCode.codeErrorMessages.allResubmissionsExhausted,
            description: locales.checkCode.codeErrorMessages.codeRequestCountExceeded
          });

          return;
        }

        setScreenData({screen: FORM_SCREENS.error});
      })
      .catch(() => {
        setScreenData({screen: FORM_SCREENS.error});
      });
  }, [verificationCodeData, setVerificationCodeData, setScreenData]);


  const completeVerificationWithError = React.useCallback(() => {
    setScreenData({
      isButtonVisible: false,
      screen: FORM_SCREENS.error, 
      title: locales.checkCode.codeErrorMessages.allResubmissionsExhausted,
      description: locales.checkCode.codeErrorMessages.codeRequestCountExceeded
    });
  }, [setScreenData]);

  const startPhoneNumberVerification = React.useCallback((formData) => {
    
    resetCodeErrorMessage();

    setScreenData({screen: 'process'});

    const contactFieldName = verificationFieldNames[verificationType];

    let contact = formData[contactFieldName];

    if (verificationType === VerificationType.Sms) {
      contact = `7${contact.slice(-10)}`;
    }

    getCodeStatusByContact(contact, verificationType)
       .then((smsStatus) => {
        if (smsStatus.codeStatus === 'success') {
          setFormValues({
            ...formData, 
            uuid: smsStatus.data.uuid, 
            verificationType: smsStatus.data.verificationType
          });
          setVerificationCodeData((prevState) => ({ ...prevState, ...smsStatus.data }));
          setScreenData({screen: FORM_SCREENS.codeCheck});
        } else {
          setScreenData({screen: FORM_SCREENS.error});
        }
      })
      .catch((error) => {
        const errorCode = error.response?.status;

        if (errorCode === ResponseCode.TooManyRequests) {
          setScreenData({
            isButtonVisible: false,
            screen: FORM_SCREENS.error, 
            title: locales.checkCode.codeErrorMessages.codeTooManyRequests.title,
            description: locales.checkCode.codeErrorMessages.codeTooManyRequests.description
          });
          return;
        }

        setScreenData({screen: FORM_SCREENS.error});
      });

  }, [setFormValues, setVerificationCodeData, setScreenData]);

  const onOpen = React.useCallback(() => {
    setScreenData({ screen: FORM_SCREEN_DEFAULT });

    setIsOpen(true);
  }, [setScreenData, setIsOpen]);

  const onClose = React.useCallback(() => {
    setIsOpen(false);

  }, [setScreenData, setIsOpen])


  const resultsMessagesScreen = React.useMemo(() => {
    let title = '';
    let description = '';

    if (screenData.screen === FORM_SCREENS.error) {
      title = screenData.title || locales.formUser.result.error;
      description = screenData.description || locales.formUser.result.errorDescription;
    }

    if (screenData.screen === FORM_SCREENS.success) {
      title = screenData.title || locales.formUser.result.success;
      description = screenData.description || locales.formUser.result.successDescription;
    }

    return {
      title,
      description
    }
  }, [locales, screenData]);

  return (
    <div ref={ref}>
      <div className={`button-fos-wrapper ${canBeFixed === "true" ? "button-fos-wrapper-canfixed" : ""} ${inView === false ? "fixedBtn": ""}`}>
        {openFormLinkTitle && <LinkButton
          className="ContactBank-LinkButtonBtnContact"
          onClick={onOpen}
          title={openFormLinkTitle}
        />}
        {openFormButtonTitle && <Button click={onOpen}>
            {openFormButtonTitle}
        </Button>}
      </div>

      <Modal
        className="callBackForm"
        closeTimeoutMS={100}
        isOpen={modalIsOpen}
        onRequestClose={onClose}
        overlayClassName="callBackForm-Overlay"
        portalClassName="FOS-callBackForm"
      >
        {shouldShownBlackListModal && <BlackListCheck />}
        {!shouldShownBlackListModal && screenData.screen === FORM_SCREENS.form && (
          <FormComponent 
            onSubmit={startPhoneNumberVerification}
          />
        )}

        {!shouldShownBlackListModal && screenData.screen === FORM_SCREENS.process && <ProcessScreen text={locales.formProcess} />}

        {!shouldShownBlackListModal && screenData.screen === FORM_SCREENS.codeCheck && (
          <CodeCheck 
            contact={verificationCodeData.contact}
            codeErrorMessage={verificationCodeData.codeErrorMessage}
            seconds={verificationCodeData.checkLeftSeconds} 
            repeatCountLeft={verificationCodeData.repeatCountLeft}
            verificationType={verificationType}
            completeVerificationWithError={completeVerificationWithError}
            handleCheckCode={sendSmsCode}
            handleResendCode={resendCode}
            handleResetSeconds={resetSmsCodeSeconds}
          />
        )}

        {!shouldShownBlackListModal && (screenData.screen === FORM_SCREENS.success || screenData.screen === FORM_SCREENS.error) &&
           <ResultScreen
              isButtonVisible={screenData.isButtonVisible}
              buttonTitle={locales.formUser.result.button}
              description={resultsMessagesScreen.description} 
              title={resultsMessagesScreen.title} 
              status={screenData.screen}
              handleButtonClick={handleResultButtonClick} 
            />
        }
        
        <a onClick={onClose}>
          <img src={iconClose} className="callBackForm-iconClose" />
          <img src={iconCloseMobile} className="callBackForm-iconCloseMobile" />
        </a>
      </Modal>
    </div>
  );
};

FormVerificationWrapper.propTypes = {
  canBeFixed: PropTypes.oneOf(['true', 'false']),
  form: PropTypes.element.isRequired,
  formFieldValues: PropTypes.object.isRequired,
  openFormButtonTitle: PropTypes.string,
  openFormLinkTitle: PropTypes.string,
  reachGoalTitle: PropTypes.string,
  verificationType: PropTypes.oneOf(['email', 'sms']),
  signUpFormOpened: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
};

export default FormVerificationWrapper;
