import React, {useContext} from "react";
import "./BlackListCheck.scss";
import useLanguage from "../../../hooks/useLanguage.js";
import {MESSAGES} from "./constants.js";
import WarningIcon from "../../../images/icons/form/warning.svg";
import {BLACK_LIST_STATUSES, BlacklistContext} from "../../../context/BlacklistContext";

const BlackListCheck = React.memo(() => {
  const [language] = useLanguage();
  const {blackListStatus} = useContext(BlacklistContext);

  if (!blackListStatus || blackListStatus === BLACK_LIST_STATUSES.NOT_BLOCKED) {
    return null;
  }

  const { title, description, footer } = MESSAGES[language][blackListStatus];

  return (
    <div
      className={
        "BlackListCheck" +
        (blackListStatus === "loading" ? " BlackListCheck--loading" : "")
      }
    >
      <div className="BlackListCheck__container">
        <div
          className={`BlackListCheck__icon-wrapper ${
            blackListStatus !== "loading" ? "BlackListCheck__icon-wrapper--shadow" : ""
          }`}
        >
          {blackListStatus === "loading" ? (
            <div className="BlackListCheck__icon BlackListCheck__icon--loading"></div>
          ) : (
            <img className="BlackListCheck__icon" src={WarningIcon} />
          )}
        </div>
        <span className="BlackListCheck__title">{title}</span>
        <p className="BlackListCheck__description">{description}</p>
      </div>

      {footer && (
        <p
          className="BlackListCheck__footer"
          dangerouslySetInnerHTML={{ __html: footer }}
        />
      )}
    </div>
  );
});

export default BlackListCheck;
